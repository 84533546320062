import { useState } from 'react';
import { Location, Mail, Phone } from '../../assets/svgs';
import { Button } from '../../ui';
import { Container } from './styles';
import { axios } from '../../library';
import { Apiurls } from '../../constants';
import { useNotify } from '../../hooks';

const ContactUs = () => {
	const notify = useNotify();
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		email: '',
		message: '',
	});

	const handleChange = (event) => {
		const name = event.target.name || '';
		const value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = async (event) => {
		event.preventDefault();

		setLoading(true);

		try {
			await axios.post(Apiurls.contact, formData);
			notify({ message: 'Your message has been submitted!', variant: 'major', status: 'success' });
		} catch (error) {
			notify({ message: 'Unable to submit your message!', variant: 'major', status: 'error' });
		} finally {
			setFormData({
				email: '',
				message: '',
			});
			setLoading(false);
		}
	};

	return (
		<Container id="contact">
			<h3>Contact Us</h3>
			<p>
				From optimizing processes to harnessing cutting-edge technologies, we're here to attend to transform your business with our strategic IT
				expertise. Contact us now to get started!
			</p>

			<div>
				<div className="info">
					<p>You can reach us on our hotline or free free to write us a mail and we respond to you the soonest</p>
					<div>
						<Phone />
						+477-8556-7851
					</div>
					<div className="address">
						<Location />
						<span>
							1 Quality Court, Chancery Lane, <br /> London WC2A 1HR
						</span>
					</div>
					<div>
						<Mail />
						info@descinder.com
					</div>
				</div>

				<form onSubmit={handleSubmit}>
					<input type="email" name="email" placeholder="Enter email" required value={formData.email} onChange={handleChange} />
					<textarea
						name="message"
						cols="30"
						rows="3"
						placeholder="Write something..."
						required
						value={formData.message}
						onChange={handleChange}
					></textarea>
					<Button loading={loading}>Submit</Button>
				</form>
			</div>
		</Container>
	);
};

export default ContactUs;
