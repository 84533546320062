import { useState } from 'react';
import { ModalContext } from '../../context';
import {
	ContactUs,
	Footer,
	Benefits,
	HeroSection,
	HowItWorks,
	EmpowerSection,
	ConsultNow,
	TermsAndConditions,
	Application,
	SeamlessSection,
	ConnectingSection,
	SubscriptionPlan,
	EmpoweringSection,
	OneStopSection,
} from '../../components';

const HomePage = () => {
	const [modals, setModals] = useState({
		showConsult: false,
		showApplication: false,
		applicationType: 'start-up',
		showTerms: false,
	});

	return (
		<ModalContext.Provider value={{ modals, setModals }}>
			<HeroSection />
			<Benefits />
			{/* <ProductSection /> */}
			{/* <SoftwareSection /> */}
			<ConnectingSection />
			<SeamlessSection />
			<EmpoweringSection />
			<OneStopSection />
			<EmpowerSection />
			<HowItWorks />
			{/* <ProjectDone /> */}
			<SubscriptionPlan />
			<ContactUs />
			<Footer />

			{/* Modals */}
			{modals.showConsult ? <ConsultNow /> : null}
			{modals.showTerms ? <TermsAndConditions /> : null}
			{modals.showApplication ? <Application /> : null}
		</ModalContext.Provider>
	);
};

export default HomePage;
